import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography, 
  Slider, 
  Switch, 
  Button, 
  FormControlLabel,
  Card,
  CardContent,
  CardMedia,
  Box,
} from '@mui/material';
import taskData from '../taskData.json';

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState(null);
  const [autoAdvanceTime, setAutoAdvanceTime] = useState(4);
  const [showScore, setShowScore] = useState(false);
  const [randomOrder, setRandomOrder] = useState(false);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
  };

  const handleStartTask = () => {
    if (selectedTask) {
      navigate(`/task/${selectedTask.id}`, { 
        state: { autoAdvanceTime, showScore, randomOrder }
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ mb: 6, fontWeight: 300 }}>
        Eloquent Aid
        {/* by Eddie Guo then add link to https://eddieguo.ca/ */}
        <Typography variant="h5" component="p" align="center" sx={{ mb: 3, marginTop: '1rem', fontSize: 20 }}>
            By <a href="https://eddieguo.ca/" target="_blank" rel="noreferrer">Eddie Guo</a>
        </Typography>
      </Typography>
      
      <Grid container spacing={4} sx={{ mb: 6 }}>
        {taskData.map((task) => (
          <Grid item xs={12} sm={6} md={4} key={task.id}>
            <Card 
              onClick={() => handleTaskSelect(task)}
              sx={{
                cursor: 'pointer',
                border: selectedTask?.id === task.id ? '2px solid #1976d2' : '1px solid rgba(0,0,0,0.2)',
                transition: 'all 0.1s ease-in-out',
                width: '100%',
                height: '325px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '&:hover': {
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                },
              }}
            >
              <CardMedia
                component="img"
                image={task.image}
                alt={task.name}
                sx={{
                  height: '200px',
                  objectFit: 'contain',
                  marginTop: '10px',
                }}
              />
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {task.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task.images.length} images
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ bgcolor: '#f5f5f5', p: 4, borderRadius: 2, mb: 4, position: 'relative' }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
          Settings
        </Typography>

        <Typography id="auto-advance-slider" gutterBottom>
          Auto Advance Time: {autoAdvanceTime} seconds
        </Typography>
        <Slider
          value={autoAdvanceTime}
          onChange={(_, newValue) => setAutoAdvanceTime(newValue)}
          aria-labelledby="auto-advance-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={10}
          sx={{ mb: 3 }}
        />

        <FormControlLabel
          control={<Switch checked={randomOrder} onChange={(e) => setRandomOrder(e.target.checked)} />}
          label="Random Image Order"
          sx={{ mb: 1, display: 'block' }}
        />

        <FormControlLabel
          control={<Switch checked={showScore} onChange={(e) => setShowScore(e.target.checked)} />}
          label="Show Running Score"
          sx={{ mb: 1, display: 'block' }}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartTask}
          disabled={!selectedTask}
          size="large"
          sx={{ 
            px: 4, 
            py: 1.5, 
            fontSize: '1.1rem',
            textTransform: 'none',
          }}
        >
          Start Task
        </Button>
      </Box>

      <Box sx={{ mt: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Typography variant="caption" sx={{ pt: 2 }}>
          © Eddie Guo 2024-{new Date().getFullYear()}
        </Typography>
      </Box>
    </Container>
  );
};

export default HomePage;