import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button,
} from '@mui/material';

const ResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { score, total } = location.state || { score: 0, total: 0 };

  const percentage = total > 0 ? Math.round((score / total) * 1000) / 10 : 0;

  const handleReturnHome = () => {
    navigate('/', { replace: true });
  };

  return (
    <Container maxWidth="sm" sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center' 
    }}>
      <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ fontSize: '4rem' }}>
        Final Score
      </Typography>
      <Typography variant="h2" align="center" color="primary" sx={{ fontSize: '6rem', marginBottom: '2rem' }}>
        {score} / {total}
      </Typography>
      <Typography variant="h3" align="center" color="secondary" sx={{ marginBottom: '2rem' }}>
        {percentage}%
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleReturnHome}
        size="large"
        sx={{ fontSize: '1.5rem', padding: '0.5rem 2rem' }}
      >
        Return Home
      </Button>
    </Container>
  );
};

export default ResultsPage;