import React from 'react';
import { LinearProgress, Box } from '@mui/material';

const TimerBar = ({ progress }) => {
  return (
    <Box sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
      <LinearProgress 
        variant="determinate" 
        value={progress} 
        sx={{
          height: 10,
          borderRadius: 5,
          '& .MuiLinearProgress-bar': {
            borderRadius: 5,
          },
        }}
      />
    </Box>
  );
};

export default TimerBar;