import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  Box,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import HomeIcon from '@mui/icons-material/Home';
import taskData from '../taskData.json';
import VoiceRecognition from '../components/VoiceRecognition';
import TimerBar from '../components/TimerBar';

const TaskPage = () => {
  const { taskId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { autoAdvanceTime, showScore, randomOrder } = location.state || {};

  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [totalAnswered, setTotalAnswered] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(100);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isTaskComplete, setIsTaskComplete] = useState(false);

  const audioRef = useRef(new Audio());
  const timerRef = useRef(null);
  const remainingTimeRef = useRef(autoAdvanceTime * 1000);
  const currentImageRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleTimerEnd = useCallback(() => {
    console.log("Timer ended, stopping voice recognition");
    setIsListening(false);
    setIsProcessing(true);
  }, []);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const startTime = Date.now();
    setProgress(100);
    setIsListening(true);
    setIsProcessing(false);
    if (images[currentImageIndex]) {
      currentImageRef.current = images[currentImageIndex];
    }
    timerRef.current = setInterval(() => {
      if (!isPaused && !abortControllerRef.current.signal.aborted) {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = remainingTimeRef.current - elapsedTime;
        if (remainingTime <= 0) {
          clearInterval(timerRef.current);
          handleTimerEnd();
        } else {
          setProgress((remainingTime / (autoAdvanceTime * 1000)) * 100);
        }
      }
    }, 100);
  }, [autoAdvanceTime, currentImageIndex, images, isPaused, handleTimerEnd]);

  const playSound = useCallback((type) => {
    return new Promise((resolve) => {
      if (!isPaused) {
        audioRef.current.src = `/sounds/${type}.mp3`;
        audioRef.current.play();
        audioRef.current.onended = resolve;
      } else {
        resolve();
      }
    });
  }, [isPaused]);

  const advanceToNextImage = useCallback(() => {
    if (!isPaused && !abortControllerRef.current.signal.aborted) {
      console.log(`Current image index: ${currentImageIndex}, Total images: ${images.length}`);
      if (currentImageIndex < images.length - 1) {
        setCurrentImageIndex(prevIndex => {
          const newIndex = prevIndex + 1;
          console.log(`Advancing to next image. New index: ${newIndex}`);
          return newIndex;
        });
        setImageLoaded(false);
        remainingTimeRef.current = autoAdvanceTime * 1000;
        setProgress(100);
        setIsListening(false);
        setIsProcessing(false);
      } else {
        console.log('Reached last image. Completing task.');
        setIsTaskComplete(true);
        setIsListening(false);
        setIsProcessing(false);
      }
    } else {
      console.log('Task is paused or aborted. Not advancing.');
    }
  }, [isPaused, currentImageIndex, images.length, autoAdvanceTime]);

  const handleVoiceResult = useCallback(async (transcript) => {
    if (isPaused || abortControllerRef.current.signal.aborted) {
        console.log('Task is paused or aborted. Not processing voice result.');
        return;
    }
  
    console.log(`Processing voice result for image ${currentImageIndex + 1}/${images.length}`);
    console.log("Voice recognition result received:", transcript);
   
    const recordedImage = currentImageRef.current;
    console.log("Recorded image:", recordedImage);
   
    if (recordedImage && recordedImage.answer) {
      const correctAnswers = recordedImage.answer.toLowerCase().split(' or ');
     
      console.log('Correct answers:', correctAnswers);
  
      const isCorrect = correctAnswers.some(answer => 
        transcript.toLowerCase().includes(answer.trim())
      );
  
      console.log('Answer is correct:', isCorrect);
   
      setScore(prevScore => {
        const newScore = isCorrect ? prevScore + 1 : prevScore;
        console.log('Updated score:', newScore);
        return newScore;
      });
      setTotalAnswered(prevTotal => {
        const newTotal = prevTotal + 1;
        console.log('Updated total answered:', newTotal);
        return newTotal;
      });
  
      if (isCorrect) {
        await playSound('correct');
      } else {
        await playSound('incorrect');
      }
   
    } else {
      console.error('Recorded image or its answer is undefined');
    }
   
    advanceToNextImage();
  }, [isPaused, playSound, advanceToNextImage, currentImageIndex, images.length]);

  useEffect(() => {
    console.log('Checking if task is complete:', isTaskComplete);
    if (isTaskComplete) {
      console.log('Task is complete. Preparing to navigate to results page.');
      console.log('Final score:', score, 'Total answered:', totalAnswered);
      navigate('/results', { 
        state: { 
          score: score, 
          total: totalAnswered 
        },
        replace: true
      });
    }
  }, [isTaskComplete, navigate, score, totalAnswered]);

  useEffect(() => {
    const task = taskData.find(t => t.id === taskId);
    if (task) {
      const shuffledImages = randomOrder ? shuffleArray(task.images) : task.images;
      setImages(shuffledImages);
      console.log("Images set:", shuffledImages);
      setIsLoading(false);
    } else {
      setError('Task not found');
      setIsLoading(false);
    }
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [taskId, randomOrder]);

  useEffect(() => {
    if (imageLoaded && !isPaused && !isTaskComplete) {
      startTimer();
    }
  }, [imageLoaded, isPaused, isTaskComplete, startTimer]);
  
  useEffect(() => {
    if (images.length > 0) {
      console.log(`Image index updated: ${currentImageIndex}/${images.length - 1}`);
      if (currentImageIndex >= images.length) {
        console.log('On last image, marking task as complete.');
        setIsTaskComplete(true);
      }
    }
  }, [currentImageIndex, images.length]);

  const handlePauseResume = () => {
    if (isPaused) {
      // Resuming
      abortControllerRef.current = new AbortController();
      setIsPaused(false);
      startTimer();
    } else {
      // Pausing
      abortControllerRef.current.abort();
      setIsPaused(true);
      clearInterval(timerRef.current);
      setIsListening(false);
      setIsProcessing(false);
    }
  };

  const handleReturnHome = () => {
    abortControllerRef.current.abort();
    setIsPaused(true);
    clearInterval(timerRef.current);
    setIsListening(false);
    setIsProcessing(false);
    navigate('/');
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="md" sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh',
        py: 2
      }}>
        {!isTaskComplete && images[currentImageIndex] && (
          <Typography variant="h6" align="center" gutterBottom>
            Image {currentImageIndex + 1} of {images.length}
          </Typography>
        )}
      <Box 
        flex={1} 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center"
        position="relative"
      >
        {!isTaskComplete && images[currentImageIndex] && (
            <img
                src={images[currentImageIndex]?.src} 
                alt="Task"
                style={{ 
                maxWidth: '100%', 
                height: isMobile ? '50vh' : '60vh', 
                objectFit: 'contain' 
                }}
                onLoad={handleImageLoad}
            />
        )}
        {imageLoaded && !isPaused && !isTaskComplete && <TimerBar progress={progress} />}
      </Box>

      <Typography variant="body1" align="center" my={2}>
        {isPaused ? 'Paused' : isListening ? 'Listening...' : isProcessing ? 'Processing...' : 'Get ready...'}
      </Typography>

      <Box 
        mt="auto" 
        mb={2} 
        display="flex" 
        justifyContent="space-between"
        width="100%"
      >
        <Button 
          onClick={handleReturnHome}
          startIcon={<HomeIcon />}
          variant="contained"
        >
          HOME
        </Button>

        {showScore && (
            <Typography variant="h6" gutterBottom>
            Score: {score} / {totalAnswered}
            </Typography>
        )}

        {!isTaskComplete && (
          <Button 
            onClick={handlePauseResume} 
            startIcon={isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            variant="contained"
          >
            {isPaused ? 'RESUME' : 'PAUSE'}
          </Button>
        )}
      </Box>

      <VoiceRecognition 
        isListening={isListening && !isPaused && !isTaskComplete}
        onResult={handleVoiceResult}
      />
    </Container>
  );
};

export default TaskPage;