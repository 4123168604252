import { useEffect, useRef, useCallback } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';

const VoiceRecognition = ({ isListening, onResult }) => {
  const recorderRef = useRef(null);
  const timeoutRef = useRef(null);

  const startRecording = useCallback(() => {
    console.log('Starting recording');
    
    if (!recorderRef.current) {
      recorderRef.current = new MicRecorder({ bitRate: 128 });
    }

    recorderRef.current.start()
      .then(() => {
        console.log('Recorder started');
        timeoutRef.current = setTimeout(() => {
          console.log('Recording timeout reached');
          stopRecording();
        }, 5000);
      })
      .catch((error) => {
        console.error('Error starting recording:', error);
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          alert('Permission to access microphone was denied. Please enable it in your browser settings.');
        }
      });
  }, []);

  const stopRecording = useCallback(() => {
    console.log('Stopping recording');
    if (recorderRef.current) {
      recorderRef.current.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          sendAudioToServer(blob);
        })
        .catch((error) => {
          console.error('Error stopping recording:', error);
        });
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const sendAudioToServer = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');
    formData.append('model', 'whisper-1');

    try {
      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Transcription received:', data.text);
      onResult(data.text);
    } catch (error) {
      console.error('Error sending audio to OpenAI:', error);
      onResult('');
    }
  };

  useEffect(() => {
    if (isListening) {
      startRecording();
    } else {
      stopRecording();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (recorderRef.current) {
        recorderRef.current.stop();
      }
    };
  }, [isListening, startRecording, stopRecording]);

  return null;
};

export default VoiceRecognition;